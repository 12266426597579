* {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

main.test-wrapper {
   width: 100%;
   height: 100vh;
   display: flex;

   .wrapper {
      width: 40%;
      padding: clamp(2rem, 4vw, 6rem);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;


      h2 {
         color: $c1;
         font-size: clamp(2rem, 2vw, 4rem);
         margin-bottom: 3rem;
         text-align: center;
      }

      h4 {
         color: $text;
         text-align: center;
         font-size: clamp(1.2rem, 2vw, 1.4rem);
         margin-bottom: 2rem;
         opacity: .7;
      }

      .nav-view {
         width: 100%;
         text-align: center;

         .nav-button {
            @include button;
            display: inline-block;
         }
      }

      .button-default {
         @include button;
         text-align: center;
      }

      .multiple-buttons {
         .button-default {
            margin: 10px 0px;
         }
      }

      .specs-table {
         display: flex;
         flex-wrap: wrap;
         width: 100%;
         margin-bottom: 2rem;

         .form-item {
            h5 {
               font-size: .9rem;
            }
         }

         .form-item-wrapper {
            display: grid;
            width: 100%;
            gap: 4px;
            grid-template-columns: repeat(3, 1fr);
         }

         @media (max-width: 1080px) {
            .form-item-wrapper {
               grid-template-columns: repeat(1, 1fr);
            }
         }

         h2 {
            color: $c2;
            flex: 100%;
            text-align: left;
            font-size: 1.3rem;
            margin-bottom: 1rem;
         }


      }

      .space {
         margin: 1rem 0;
         display: inline-block;
      }

      .specs-table-lens {
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         grid-gap: 20px;

         h2 {
            grid-column: 1/4;

            color: $c2;
            text-align: left;
            font-size: 1.3rem;
            margin-bottom: 1rem;
         }

         .form-item {
            h5 {
               font-size: .9rem;
            }
         }
      }
      
      .specs-info-wrapper {
         display: grid;
         gap: 30px;

         * {
            text-align: left;
         }

         .specs-info-options {
            display: flex;
            gap: 10px;
            align-items: center;

         }

         input {
            width: 16px;
            height: 16px;
         }

         label, .form-check-label {
            font-size: 1.08rem !important;
            font-weight: 500 !important;
            color: $text;
            text-transform: none;
         }
      }
      
      
      .specs-table-lens-brand {
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         grid-gap: 20px;
         margin-top: 1rem;

         .brand {
            grid-column: 1/3;
            margin: 0;
         }
      }
   }

   .image {
      flex: 1;
      background: #f1f1f1;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
}


.card-bill-screen {
   width: 100%;
   height: 100vh;
   display: flex;
   align-items: center;
   flex-direction: column;
   padding-top: 10vh;
   font-family: $font;
   background: linear-gradient(0deg, rgba(255,255,255,1) 57%, rgba(203,236,238,1) 100%);

   h2 {
      color: $c1;
      font-size: clamp(2rem, 2vw, 4rem);
      margin-bottom: 3rem;
      text-align: center;
   }

   h5 {
      color: $text;
      text-align: center;
      font-size: clamp(1.2rem, 2vw, 1.4rem);
      margin-bottom: 2rem;
      opacity: .7;
   }

   .nav-button {
      @include button;
      display: inline-block;
   }

   .option-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .option-button {
         background: #FFFFFF;
         box-shadow: 0 2px 68px 0 rgba(0,0,0,0.13);
         margin: 0px 1rem;
         padding: 10px;
         border-radius: 10px;
         cursor: pointer;

         &:hover {
            outline: 2px solid $c1;
            color: $c1;
         }
      }
   }

   .resize-viewport {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5rem 0;
   }
}


.qrcode-view {
   background: linear-gradient(0deg, rgba(255,255,255,1) 57%, rgba(203,236,238,1) 100%);
   width: 100%;
   height: 100vh;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   font-family: $font;

   h2 {
      color: $c1;
      font-size: clamp(2rem, 2vw, 4rem);
      margin-bottom: 3rem;
      text-align: center;
      max-width: 600px;
   }

   .qr-code-wrapper {
      background: #FFFFFF;
      box-shadow: 0 2px 68px 0 rgba(0,0,0,0.13);
      display: flex;
      flex-direction: column;
      padding: 3rem;
      align-items: center;
      justify-content: center;
      border-radius: 30px;

      svg {
         margin: 0;
      }

      a {
         color: $c2;
         font-weight: bold;
         margin-top: 20px;
      }
   }

}

.mobile-view {
   background: linear-gradient(0deg, rgba(255,255,255,1) 57%, rgba(203,236,238,1) 100%);
   width: 100%;
   height: 100vh;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   font-family: $font;

   h1 {
      color: $c1;
      font-size: clamp(2rem, 2vw, 4rem);
      margin-bottom: 3rem;
      text-align: center;
      max-width: 600px;
   }

   h2 {
      color: $text;
      font-size: clamp(1rem, 2vw, 1.8rem);
      margin-bottom: 3rem;
      text-align: center;
      max-width: 600px;
   }

   .image-view {
      width: 100%;
      max-width: 1400px;
      text-align: center;
      margin-bottom: 3rem;
   }

   .nav-view {
      margin: 4rem 0;
      .nav-button {
         @include button;
         display: inline-block;
      }
   }

   .nav-buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 999px) {
         flex-direction: column;
      }

      .nav-button {
         @include button;
         display: inline-block;
         margin: 0px 10px;

         @media (max-width: 999px) {
            width: 100% !important;
            margin: 0;
            margin-bottom: 10px;
            text-align: center;
         }
      }
   }
}




.mobile-tests {
   width: 100%;
   height: 88vh;
   display: flex;
   flex-direction: column;

   .main-view {
      flex: 1;
      border-bottom: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;

      h2 {
         color: $c1;
         font-size: clamp(2rem, 2vw, 4rem);
         margin-bottom: 3rem;
         text-align: center;
         max-width: 600px;
         font-family: $font;
         margin: 15px 0px;
         margin-top: 20px;
      }

      &-inset {
         flex: 1;
         display: flex;
         flex-direction: column;

         &-wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            canvas {
               &:active {
                  opacity: .5;
               }
            }
         }
      }

   }

   .main-nav {
      padding: 15px;
      display: flex;
      flex-direction: column;

      .button-light {
         background: rgba($c1, .1);
         color: $c1;
         font-family: $font;
         padding: 15px 0px;
         text-align: center;
         font-size: 1.2rem;
         font-weight: 500;
         border-radius: 8px;
         margin-bottom: 15px;
         cursor: pointer;

         &:active {
            outline: 2px solid $c1
         }
      }

      .button-default {
         @include button;
         text-align: center;
      }
   }

}



.test-instructions-wrapper {
   width: 100%;
   height: 100vh;
   display: flex;
   flex-direction: column;
   background: linear-gradient(0deg, rgba(255,255,255,1) 77%, rgba(203,236,238,1) 100%);
   position: relative;
   z-index: 1;

   h1 {
      font-family: $font;
      color: $c1;
   }

   .test-instructions {
      text-align: center;
      padding: 3rem 0px;
      border-bottom: 2px solid rgba($c1, .1);
      font-family: $font;

      .redtext {
         color: $c1;
         font-weight: bold;
      }

      .redbg {
         background-color: $c1;
      }
   }

   .canvas-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
   }
}


.thank-you-page {
   width: 100%;
   margin-top: 150px;
   margin-bottom: 50px;


   &-wrapper {
      margin: 0 auto;
      width: 100%;
      max-width: 800px;
      border: 1px solid #f1f1f1;
      padding: 40px;
      background: #FFFFFF;
      box-shadow: 0 2px 68px 0 rgba(0,0,0,0.13);
      border-bottom: 2px dashed #e5e5e5;
      border-radius: 10px;
      font-family: $font;

      h5 {
         margin-top: 2rem;
         margin-bottom: 4rem;
      }

      h1 {
         color: $c1;
         margin-bottom: 2rem;
      }

      h2 {
         font-size: 1.3rem;
         color: $c2;
         font-weight: bold;
      }

      p {
         margin: 0;
         font-size: 1.2rem;
         margin-bottom: 10px;
      }

      hr {
         margin: 2rem 0;
      }
   }
}


.connectionContent {
   background: linear-gradient(0deg, rgba(255,255,255,1) 77%, rgba(203,236,238,1) 100%);
   font-family: $font;

   h1 {
      color: $c1;
      margin-bottom: 2rem;
   }


   h2 {
      font-size: clamp(1.2rem, 2vw, 1.6rem);
      color: $c2;
      font-weight: bold;
   }
}