.redtext{
    color: rgb(220,0,0);
}

.redbg{
    background-color: rgb(220,0,0);
    color: rgb(255,255,255);
}

.frame{
    /* background-color: rgb(147, 171, 190); */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
    height: 100vh;
}

/* h1{
    color: #007BFF;
} */

.answerArea{
    /* background-color: rgb(30, 72, 107); */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-bottom: 50px;

}

.answerAreaRyan{
    /* background-color: rgb(30, 72, 107); */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    align-items: flex-end;
}

.answerAreaRyan2{
    /* background-color: rgb(30, 72, 107); */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-bottom: 20px;
    align-items: flex-end;
    flex: 1;
}

.answerAreaRyan3{
    background-color: rgb(30, 72, 107);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-bottom: 20px;
    align-items: flex-end;
    flex: 1;
}

.ryanText{
    display: flex;
    align-items:center;
    flex-direction: column;
    font-size: larger;

}


.button{
    height: 100px;
    width: 100px;
    border: 2px solid gray;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 30px;
    font-size: larger;
}

.Button2 {
    display: block;
    font-size: 16px;
    width: 190px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 40px 15px 0;
    background-color: #2485ff;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #2485ff;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}
.connectionContent{
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgb(162, 162, 162); */
    /* opacity: 0.3; */
    z-index: 6000;
    text-align: center;
    color: rgb(0, 0, 0);
    align-items:center;
    justify-content:center;
    flex-direction: column;
}

.connectionContentTitle{
    margin-bottom: 30px;
}
.connectionContentText{
margin-bottom: 60px;
}

textarea:focus, input:focus{
    outline: none;
}

/*stripe*/

* {
    box-sizing: border-box;
}

/* input,
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
} */
#email {
border-radius: 5px;
margin-bottom: 2px;
padding: 0.75rem; /*#12px;*/
border: 1px solid rgba(230, 230, 230);
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02);
max-height: 44px;
font-size: 16px;
width: 100%;
background: white;
box-sizing: border-box;
}

#email:focus {
border: 1px solid #7ab9fd;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 3px rgba(33, 117, 207, 0.24);
}

#emailerror {
border-radius: 5px;
margin-bottom: 2px;
padding: 0.75rem; /*#12px;*/
border: 1px solid #df1b41;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px #df1b41;
max-height: 44px;
font-size: 16px;
width: 100%;
background: white;
box-sizing: border-box;
}

#payment-message {
color: rgb(105, 115, 134);
font-size: 16px;
line-height: 20px;
padding-top: 12px;
text-align: center;
}

#payment-element {
margin-bottom: 24px;
}

/* Buttons and links */
button {
background: #5469d4;
font-family: Arial, sans-serif;
color: #ffffff;
border-radius: 4px;
border: 0;
padding: 12px 16px;
font-size: 16px;
font-weight: 600;
cursor: pointer;
display: block;
transition: all 0.2s ease;
box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
width: 100%;
}

button:hover {
filter: contrast(115%);
}

button:disabled {
opacity: 0.5;
cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
border-radius: 50%;
}

.spinner {
color: #ffffff;
font-size: 22px;
text-indent: -99999px;
margin: 0px auto;
position: relative;
width: 20px;
height: 20px;
box-shadow: inset 0 0 0 2px;
-webkit-transform: translateZ(0);
-ms-transform: translateZ(0);
transform: translateZ(0);
}

.spinner:before,
.spinner:after {
position: absolute;
content: '';
}

.spinner:before {
width: 10.4px;
height: 20.4px;
background: #5469d4;
border-radius: 20.4px 0 0 20.4px;
top: -0.2px;
left: -0.2px;
-webkit-transform-origin: 10.4px 10.2px;
transform-origin: 10.4px 10.2px;
-webkit-animation: loading 2s infinite ease 1.5s;
animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
width: 10.4px;
height: 10.2px;
background: #5469d4;
border-radius: 0 10.2px 10.2px 0;
top: -0.1px;
left: 10.2px;
-webkit-transform-origin: 0px 10.2px;
transform-origin: 0px 10.2px;
-webkit-animation: loading 2s infinite ease;
animation: loading 2s infinite ease;
}

@keyframes loading {
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
}

@media only screen and (max-width: 600px) {
form {
    width: 80vw;
    min-width: initial;
}
}

html {
    /*background-color: #6772e5;*/
    font-size: 16px;
    /*font-family: Roboto, Open Sans, Segoe UI, sans-serif;*/
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility;
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
}

/*#root {*/
/*    height: 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

.AppWrapper {
    width: 500px;
    height: 400px;
    /*margin-left: 100px;*/
    margin: auto;
    margin-top: 50px;

    /*position: relative;*/
}


.FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #7795f8;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
    border-radius: 4px;
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #819efc;
}

.FormRow:first-child {
    border-top: none;
}

.FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: #c4f0ff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes void-animation-out {
    0%,
    to {
        opacity: 1;
    }
}
.FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #fce883;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
}

.FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #fff;
    background-color: transparent;
    animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
    color: #87bbfd;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 0px 11px 0;
}

.SubmitButton {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #2485ff;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #2485ff;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.SubmitButton:active {
    background-color: #2485ff;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #2485ff;
    transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
    transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
}

.ErrorMessage {
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
}

.ErrorMessage svg {
    margin-right: 10px;
}

.Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
}

.ResultTitle {
    color: #fff;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
}

.ResultMessage {
    color: #9cdbff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
}

.ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
}

div h5{
color: #495868;
margin-top: 5px;
margin-bottom: 10px;
}
/* swal2-actions my-actions */
.my-actions {
    flex-wrap: nowrap;
}